import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { config } from '../config';

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      console.error('Invalid email');
      return;
    }
    try {
      const response = await fetch(`${config.apiBaseUrl}/account/password/forgot/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setSubmissionStatus('success');
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmissionStatus('error');
    }
  };

  const renderContent = () => {
    switch (submissionStatus) {
      case 'success':
        return (
          <Typography variant="body1" style={{ marginTop: '1rem' }}>
            Password reset email sent
          </Typography>
        );
      case 'error':
        return (
          <Typography variant="body1" style={{ marginTop: '1rem' }}>
            Failed to send password reset email. Please wait and try again.
          </Typography>
        );
      default:
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '1rem' }}
            >
              Reset Password
            </Button>
          </form>
        );
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            marginBottom: 2,
          }}
          alt="Bar Crawl Banner"
          src={config.logos.large}
        />
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>
        {renderContent()}
        <Typography variant="body2" style={{ marginTop: '1rem' }}>
          Remember your password?{' '}
          <Button color="primary" onClick={() => navigate('/login')}>
            Login
          </Button>
        </Typography>
      </Paper>
    </Container>
  );
};

export default ForgotPasswordPage;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button, Box } from '@mui/material';
import { loginUser } from '../services/api';
import { setTokens } from '../utils/auth';
import { config } from '../config';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlToken = params.get('token');
    if (urlToken) {
      setToken(urlToken);
    }
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password, token);
      setTokens(response.access_token, response.refresh_token);
      navigate('/barcrawl');
    } catch (error) {
      console.error('Login failed:', error);
      // TODO: Show error message to user
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
      <Box
        component="img"
        sx={{
          width: '100%',
          height: 'auto',
          marginBottom: 2,
        }}
        alt="Bar Crawl Banner"
        src={config.logos.large} 
      />
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            Login
          </Button>
        </form>
        <Typography variant="body2" style={{ marginTop: '1rem' }}>
          Don't have an account?{' '}
          <Button color="primary" onClick={() => navigate(`/register?token=${token}`)}>
            Sign Up
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
          <Button color="primary" onClick={() => navigate('/forgot-password')}>
            Forgot Password
          </Button>
        </Typography>
      </Paper>
    </Container>
  );
};

export default LoginPage;
import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, TextField, Button } from '@mui/material';
import { getUserInfo, updateUsername } from '../services/api';
import PasswordChangeForm from '../components/PasswordChangeForm';

const AccountSettingsPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [birthday, setBirthday] = useState('');
  const [openPasswordChange, setOpenPasswordChange] = useState(false);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo();
      setEmail(response.payload.email);
      setUsername(response.payload.user_name || '');
      setBirthday(response.payload.birthday);
    } catch (error) {
      console.error('Failed to fetch user info:', error);
      // TODO: Show error message to user
    }
  };

  const handleUpdateUsername = async () => {
    try {
      await updateUsername(username);
      // TODO: Show success message to user
    } catch (error) {
      console.error('Failed to update username:', error);
      // TODO: Show error message to user
    }
  };

  const handleOpenPasswordChange = () => {
    setOpenPasswordChange(true);
  };

  const handleClosePasswordChange = () => {
    setOpenPasswordChange(false);
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Account Settings
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          value={email}
          disabled
        />
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateUsername}
          style={{ marginTop: '1rem', marginRight: '1rem' }}
        >
          Update Username
        </Button>
        
        <TextField
          fullWidth
          margin="normal"
          label="Birthday"
          value={birthday}
          disabled
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenPasswordChange}
          style={{ marginTop: '1rem' }}
        >
          Change Password
        </Button>
        <PasswordChangeForm open={openPasswordChange} onClose={handleClosePasswordChange} />
      </Paper>
    </Container>
  );
};

export default AccountSettingsPage;
import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { changePassword } from '../services/api';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';

interface PasswordChangeFormProps {
  open: boolean;
  onClose: () => void;
}

const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ open, onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const pw_regex = new RegExp("^(?:(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))[A-Za-z0-9!~<>,;:_=?*+#.\"&§%°()\\|\\[\\]\\-\\$\\^\\@\\/]{8,128}$");


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    if (!newPassword.match(pw_regex)){
      setError('New password must be atleast 8 characters and meet atleast 2 other requirements');
      return;
    }

    try {
      const response = await changePassword(oldPassword, newPassword);
      if (response.payload.success) {
        setSuccess('Password change successful');
      } else {
        setError(response.details.text || 'Failed to change password');
      }
    } catch (error) {
      setError('Unable to change password, wait and try again');
    }
  };

  const handleClose = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    setSuccess('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        {success ? (
          <Typography color="primary">{success}</Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Current Password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <PasswordStrengthIndicator password={newPassword} />
            {error && <Typography color="error">{error}</Typography>}
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        {!success && (
          <Button onClick={handleSubmit} color="primary" disabled={newPassword !== confirmPassword || !newPassword.match(pw_regex)}>
            Change Password
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeForm;
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

interface SemiCircleGaugeProps {
  percentage: number;
}

const SemiCircleGauge: React.FC<SemiCircleGaugeProps> = ({ percentage }) => {
  const [animatedPercentage, setAnimatedPercentage] = useState(0);

  useEffect(() => {
    const animationDuration = 1000; // 1 second
    const steps = 60; // 60 frames per second
    const increment = percentage / steps;
    let currentPercentage = 0;

    const interval = setInterval(() => {
      currentPercentage += increment;
      if (currentPercentage >= percentage) {
        clearInterval(interval);
        setAnimatedPercentage(percentage);
      } else {
        setAnimatedPercentage(currentPercentage);
      }
    }, animationDuration / steps);

    return () => clearInterval(interval);
  }, [percentage]);

  const getColor = (value: number) => {
    if (value <= 50) return '#FF0000'; // Red
    if (value <= 70) return '#F9B200'; // Yellow
    return '#00FF00'; // Green
  };

  const color = getColor(animatedPercentage);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '200px',
        height: '100px',
        overflow: 'hidden',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          border: '10px solid #f0f0f0',
          borderTop: 'none',
          borderRight: 'none',
          transform: 'rotate(135deg)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          border: `10px solid ${color}`,
          borderTop: 'none',
          borderRight: 'none',
          transform: `rotate(${135 + (animatedPercentage * 1.8)}deg)`,
          transition: 'all 0.1s',
        }}
      />
      <Typography
        variant="h4"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          color: color,
        }}
      >
        {Math.round(animatedPercentage)}%
      </Typography>
      <Typography
        
        sx={{
          position: 'absolute',
          top: '80%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          color: color,
        }}
      >
        Group Score
      </Typography>
    </Box>
  );
};

export default SemiCircleGauge;
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { config } from './config';

// Import pages
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PasswordResetPage from './pages/PasswordResetPage';
import RegisterAndJoinPage from './pages/RegisterAndJoinPage';
import BarCrawlListPage from './pages/BarCrawlListPage';
import JoinBarCrawlPage from './pages/JoinBarCrawlPage';
import PlayPage from './pages/PlayPage';
import AccountSettingsPage from './pages/AccountSettingsPage';
import TempRegisterPage from './pages/TempRegisterPage';

// Import components
import NavBar from './components/NavBar';

// Import auth utility
import { isAuthenticated } from './utils/auth';

// Import fonts
import '@fontsource/luckiest-guy';
import '@fontsource/ubuntu';

const theme = createTheme({
  palette: {
    primary: {
      main: config.primaryColor,
    },
    secondary: {
      main: config.secondaryColor,
    },
  },
  typography: {
    fontFamily: '"Ubuntu", sans-serif',
    h1: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h2: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h3: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h4: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h5: {
      fontFamily: '"Luckiest Guy", cursive',
    },
    h6: {
      fontFamily: '"Luckiest Guy", cursive',
    },
  },
});

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <NavBar />
      {children}
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/register" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/password-reset" element={<PasswordResetPage />} />
          <Route path="/" element={<RegisterAndJoinPage />} />
          <Route path="/temp-register" element={<TempRegisterPage />} />
          <Route
            path="/barcrawl"
            element={
              <ProtectedRoute>
                <BarCrawlListPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barcrawl/join"
            element={
              <ProtectedRoute>
                <JoinBarCrawlPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barcrawl/play/:groupId"
            element={
              <ProtectedRoute>
                <PlayPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account/settings"
            element={
              <ProtectedRoute>
                <AccountSettingsPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import { Typography, Box, Grid, List, ListItem, ListItemText, ListSubheader, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import SemiCircleGauge from './SemiCircleGauge';
import { config } from '../config';

interface ScoreJson {
  quiz_score: number;
  quiz_winner: string;
  most_overall_drinks: {
    [key: string]: { user_name: string; total: number };
  };
  most_drinks_at_one_bar: {
    [key: string]: { user_name: string; drinks: number[]; total: number; step: number };
  };
  group_drink_totals: { [key: string]: number };
}

interface ResultsDisplayProps {
  scoreJson: ScoreJson;
  barcrawlJson: any; // You may want to type this more specifically
}

const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(2),
}));

const ResultsDisplay: React.FC<ResultsDisplayProps> = ({ scoreJson, barcrawlJson }) => {
  const drinkTypes = ['Beers', 'Seltzers', 'Mixed Drinks', 'Wines', 'Shots'];
  const drinkIcons = [config.icons.beer, config.icons.seltzer, config.icons.mixed_drink, config.icons.wine, config.icons.shot];

  const getBarName = (step: number) => {
    if (barcrawlJson && barcrawlJson.event && barcrawlJson.event[step]) {
      return barcrawlJson.event[step].title;
    }
    return `Bar #${step + 1}`;
  };

  const getTrophy = (index: number) => {
    switch (index) {
      case 0:
        return '🥇 ';
      case 1:
        return '🥈 ';
      case 2:
        return '🥉 ';
      default:
        return '';
    }
  };

  return (
    <Box sx={{ textAlign: 'center', color: 'text.primary' }}>
      <SemiCircleGauge percentage={scoreJson.quiz_score}></SemiCircleGauge>
      <br></br>
      <Typography sx={{ mb: 2, color: 'primary.main' }}>
        Quiz Winner: 
      </Typography>
      <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
        {scoreJson.quiz_winner}
      </Typography>
      
      <StyledBox>
        <Typography variant="h5" sx={{ mb: 1 }}>Group Drink Totals</Typography>
        <Grid container spacing={2} justifyContent="center">
          {Object.entries(scoreJson.group_drink_totals).map(([key, value]) => (
            <Grid item key={key}>
              <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <img 
                  src={drinkIcons[parseInt(key)]} 
                  alt={drinkTypes[parseInt(key)]}
                  style={{ height: '50px', marginRight: '0.25rem', cursor: 'pointer' }}
                />
                <Typography>
                  {value}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </StyledBox>
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="h5" sx={{ mb: 1 }}>Most Overall Drinks</Typography>
            <List>
              {Object.entries(scoreJson.most_overall_drinks).map(([key, value], index) => (
                <ListItem key={key}>
                  <ListItemText
                    primary={`${getTrophy(index)}${value.user_name}: ${value.total}`}
                  />
                </ListItem>
              ))}
            </List>
          </StyledBox>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="h5" sx={{ mb: 1 }}>Most Drinks at One Bar</Typography>
            <List>
              {Object.entries(scoreJson.most_drinks_at_one_bar).map(([key, value]) => (
                <ListItem key={key}>
                  <ListItemText
                    primary={`${value.user_name}: ${value.total}`}
                    secondary={`At ${getBarName(value.step)}`}
                  />
                  <ListSubheader>
                    {value.drinks.map(d => (
                      <img 
                        key={d}
                        src={drinkIcons[d]} 
                        alt={drinkTypes[d]}
                        style={{ height: '25px' }}
                      />
                    ))}
                  </ListSubheader>
                </ListItem>
              ))}
            </List>
          </StyledBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResultsDisplay;
import React from 'react';
import { LinearProgress, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface PasswordStrengthIndicatorProps {
  password: string;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ password }) => {
  const calculateStrength = (password: string): number => {
    let strength = 0;
    if (password.length >= 8) strength += 20;
    if (password.match(/[a-z]+/)) strength += 20;
    if (password.match(/[A-Z]+/)) strength += 20;
    if (password.match(/[0-9]+/)) strength += 20;
    if (password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) strength += 20;
    return strength;
  };

  const strength = calculateStrength(password);

  const getColor = (strength: number): string => {
    if (strength < 40) return '#ff0000';
    if (strength < 60) return '#ff8000';
    if (strength < 80) return '#ffff00';
    return '#00ff00';
  };

  const requirements = [
    { text: 'At least 8 characters', met: password.length >= 8 },
    { text: 'At least one lowercase letter', met: /[a-z]/.test(password) },
    { text: 'At least one uppercase letter', met: /[A-Z]/.test(password) },
    { text: 'At least one number', met: /[0-9]/.test(password) },
    { text: 'At least one symbol', met: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]/.test(password) },
  ];

  return (
    <div style={{ marginTop: '0.5rem' }}>
      <LinearProgress
        variant="determinate"
        value={strength}
        style={{ height: 10, borderRadius: 5 }}
        color="primary"
      />
      <Typography variant="caption" style={{ color: getColor(strength) }}>
        Password strength: {strength}%
      </Typography>
      <List dense>
        {requirements.map((req, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              {req.met ? (
                <CheckCircleOutlineIcon style={{ color: 'green' }} />
              ) : (
                <ErrorOutlineIcon style={{ color: 'red' }} />
              )}
            </ListItemIcon>
            <ListItemText primary={req.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default PasswordStrengthIndicator;
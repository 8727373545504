import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button } from '@mui/material';
import { joinBarCrawl } from '../services/api';

const JoinBarCrawlPage: React.FC = () => {
  const [joinCode, setJoinCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await joinBarCrawl(joinCode);
      navigate('/barcrawl');
    } catch (error) {
      console.error('Failed to join bar crawl:', error);
      // TODO: Show error message to user
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Join a Bar Crawl
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="6-Digit Join Code"
            value={joinCode}
            onChange={(e) => setJoinCode(e.target.value)}
            inputProps={{ maxLength: 7 }}
            placeholder="XXX-XXX"
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            Join Bar Crawl
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default JoinBarCrawlPage;
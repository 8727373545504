export const config = {
  //apiBaseUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000',
  apiBaseUrl: 'https://api.jmerlo.com',
  primaryColor: '#39c1e6',
  secondaryColor: '#fccf07',
  bannerImageUrl: 'https://barcrawl.nyc3.cdn.digitaloceanspaces.com/images/banners/bcbanner01.png',
  fonts: {
    heading: "'Luckiest Guy', cursive",
    listTitle: "'Luckiest Guy', cursive",
    listDetails: "'Ubuntu', sans-serif",
  },
  icons: {
    beer: '/beer.png',
    mixed_drink: '/mixed_drink.png',
    seltzer: '/seltzer.png',
    shot: '/shot.png',
    wine: '/wine.png',
  },
  logos: {
    small: "/logo_small_tsp.png" ,
    large: "/logo.png",
  },
  isProduction: process.env.REACT_APP_ENV === 'production',
};
/*

export const config = {
  //apiBaseUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000',
  apiBaseUrl: 'http://localhost:8000', //'https://api.jmerlo.com',
  primaryColor: '#39c1e6',
  secondaryColor: '#fccf07',
  bannerImageUrl: 'https://barcrawl.nyc3.cdn.digitaloceanspaces.com/images/banners/bcbanner01.png',
  fonts: {
    heading: "'Luckiest Guy', cursive",
    listTitle: "'Luckiest Guy', cursive",
    listDetails: "'Ubuntu', sans-serif",
  },
  icons: {
    beer: '',
    mixed_drink: '',
    seltzer: '',
    shot: '',
    wine: '',
  },
  logo: '',
  isProduction: process.env.REACT_APP_ENV === 'production',
};*/